<template>
  <h1 class="text-center">Hamburger Menus</h1>
  <ul>
    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr1">Nr.1</router-link>
    </li>


    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr2">Nr.2</router-link>
    </li>
    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr3">Nr.3</router-link>
    </li>

    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr4">Nr.4</router-link>
    </li>

    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr5">Nr.5</router-link>
    </li>

    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr6">Nr.6</router-link>
    </li>

    <li class="text-3xl py-4 hover:text-red-600">
      <router-link to="/hamburgermenunr7">Nr.7</router-link>
    </li>

  </ul>


</template>

<script>

export default {
  name: "HamburgerMenus",
}
</script>

<style scoped>

</style>